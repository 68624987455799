import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';

export const loginEvent = (method: 'Email' | 'Token') => {
  const analytics = getAnalytics();
  logEvent(analytics, 'login', { method });
};

export const signupEvent = () => {
  const analytics = getAnalytics();
  logEvent(analytics, 'sign_up', { method: 'Token' });
};

export const setUid = (uid: string) => {
  const analytics = getAnalytics();
  setUserId(analytics, uid);
};

export const selectContentEvent = (contentType: 'topic' | 'content', contentId: string) => {
  const analytics = getAnalytics();
  logEvent(analytics, 'select_content', { content_type: contentType, content_id: contentId });
};
