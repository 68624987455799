import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { authenticated } from '../authSlice';
import * as analytics from 'app/analytics';
import type { Store } from 'redux';

export const loadAuth = (store: Store) => {
  return onAuthStateChanged(getAuth(), (userInfo) => {
    if (userInfo) {
      store.dispatch(authenticated(userInfo));
      analytics.setUid(userInfo.uid);
    } else {
      store.dispatch(authenticated(null));
      analytics.setUid('guest');
    }
  });
};
