import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

const config: { [key: string]: any } = {
  apiKey: process.env.GATSBY_FIREBASE_APIKEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTHDOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECTID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APPID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENTID,
};

for (var key in config) {
  if (!config[key]) {
    throw new Error(`Firebase has not been configured for ${process.env.NODE_ENV} for ${key}`);
  }
}

const app = initializeApp(config);

if (process.env.GATSBY_USE_EMULATOR) {
  console.info('using emulator...');
  connectFirestoreEmulator(getFirestore(app), 'localhost', 8080);
  connectFunctionsEmulator(getFunctions(app), 'localhost', 5001);
  connectAuthEmulator(getAuth(app), 'http://localhost:9099', { disableWarnings: true });
}

export { app };
