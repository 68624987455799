import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, signOut } from 'firebase/auth';
import { getDoc, getFirestore, doc, query, collection, getDocs, setDoc, updateDoc } from 'firebase/firestore';

import type { state } from 'app';
import type { ProfileData } from '../profileSlice';
import { getUid } from './helper';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics, logEvent } from 'firebase/analytics';

export const createProfile = createAsyncThunk('profile/create', async (userProfile: state.User) => {
  const auth = getAuth();
  const firestore = getFirestore();
  const { uid } = auth.currentUser!;
  await setDoc(doc(firestore, 'users', uid), userProfile);
  return userProfile;
});

export const fetchProfile = createAsyncThunk('profile/fetch', async () => {
  const auth = getAuth();

  const firestore = getFirestore();

  const { uid, displayName, email, photoURL } = auth.currentUser!;

  const userDoc = await getDoc(doc(firestore, 'users', uid));
  if (!userDoc.exists()) {
    throw new Error('User profile not found');
  }

  const user = { ...userDoc.data(), uid, email, displayName, photoURL } as state.User;
  const topicsQuery = query(collection(firestore, `users/${uid}/topics`));
  const topicsSnapshot = await getDocs(topicsQuery);
  const topics = topicsSnapshot.docs.reduce<Record<string, state.Topic>>((topics, doc) => {
    return { ...topics, [doc.id]: doc.data() as state.Topic };
  }, {});

  const surveyQuery = query(collection(firestore, `users/${uid}/surveys`));
  const surveySnapshot = await getDocs(surveyQuery);
  const surveys = surveySnapshot.docs.reduce<Record<string, state.Survey>>((surveys, doc) => {
    return { ...surveys, [doc.id]: doc.data() as state.Survey };
  }, {});

  return { topics, surveys, user } as ProfileData;
});

export const updateProfile = createAsyncThunk('profile/update-user', async (data: Partial<state.User>) => {
  const uid = getUid();
  const firestore = getFirestore();
  await updateDoc(doc(firestore, `users/${uid}`), data);
  return data;
});

export const revokeConsent = createAsyncThunk('profile/revoke-consent', async () => {
  const auth = getAuth();
  const analytic = getAnalytics();
  const functions = getFunctions();
  const revokeConsentFn = httpsCallable(functions, 'revokeConsent');
  await revokeConsentFn();
  await signOut(auth);
  logEvent(analytic, 'revoke');
});
