import { createTheme } from '@mui/material';
import { cyan, indigo, red, teal } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    headspace: Palette['primary'];
    darkPrimary: Palette['primary'];
  }
  interface PaletteOptions {
    headspace?: PaletteOptions['primary'];
    darkPrimary: Palette['primary'];
  }
}

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    headspace: true;
    darkPrimary: true;
  }
  interface SvgIconPropsColorOverrides {
    headspace: true;
    darkPrimary: true;
  }
}

export default createTheme({
  shape: {
    borderRadius: 10,
  },
  typography: {
    allVariants: {
      color: '#000000',
    },
  },
  palette: {
    primary: {
      main: teal['300'],
    },
    darkPrimary: {
      main: teal['700'],
      dark: teal['900'],
      light: teal['600'],
      contrastText: 'white',
    },
    secondary: {
      main: indigo['500'],
    },
    headspace: {
      main: '#57a716',
      dark: '#3c8700',
      light: '#62bd19',
      contrastText: 'white',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F5F6F9',
    },
    info: {
      main: cyan[400],
    },
  },
});
