import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  sendPasswordResetEmail,
  UserInfo,
  updatePassword,
  updateProfile,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import { navigate } from 'gatsby';
import * as analytics from 'app/analytics';
import { load } from 'recaptcha-v3';

export type Credential = {
  email: string;
  password: string;
  remember?: boolean;
};

export const resetPassword = createAsyncThunk('auth/resetPassword', async (email: string): Promise<void> => {
  const auth = getAuth();
  const analytics = getAnalytics();
  await sendPasswordResetEmail(auth, email);
  logEvent(analytics, 'reset_password');
});

export const signout = createAsyncThunk('auth/signout', async (): Promise<void> => {
  const auth = getAuth();
  await signOut(auth);
  navigate('/auth/signin');
});

export const changePassword = createAsyncThunk('auth/changePassword', async (newPassword: string): Promise<void> => {
  const auth = getAuth();
  await updatePassword(auth.currentUser!, newPassword);
});

export const updatePhotoURL = createAsyncThunk('auth/updatePhoto', async (photoURL: string): Promise<
  Partial<UserInfo>
> => {
  const auth = getAuth();
  await updateProfile(auth.currentUser!, { photoURL });
  return { photoURL };
});

export const signin = createAsyncThunk('auth/signin', async (credential: Credential): Promise<void> => {
  const { email, password } = credential;
  await signInWithEmailAndPassword(getAuth(), email, password);
  analytics.loginEvent('Email');
});

export const headspaceSignin = createAsyncThunk('auth/headspace/signin', async (token: string): Promise<void> => {
  const auth = getAuth();
  const functions = getFunctions(getApp(), 'australia-southeast1');
  await setPersistence(auth, browserSessionPersistence);
  const { data: customToken } = await httpsCallable<{ token: string }, string>(
    functions,
    'getSigninToken',
  )({
    token,
  });
  await signInWithCustomToken(auth, customToken);
  analytics.loginEvent('Token');
});

export const unsubscribe = createAsyncThunk('auth/unsubscribe', async ({ email }: { email: string }) => {
  const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA_SITE_KEY;
  if (!recaptchaSiteKey) {
    return;
  }
  const recaptcha = await load(recaptchaSiteKey);
  const token = await recaptcha.execute('unsubscribe');

  const functions = getFunctions(getApp());

  await httpsCallable(
    functions,
    'unsubscribeFromEmails',
  )({
    email,
    recaptchaToken: token,
  });
});
