import { createAsyncThunk } from '@reduxjs/toolkit';

import { doc, getFirestore, updateDoc, writeBatch, serverTimestamp, Timestamp } from 'firebase/firestore';
import { actions } from '../profileSlice';
import { getUid } from './helper';

import type { state, TopicPlanItem } from 'app';
import type { RootState } from 'app/store';

/**
 * Start topics
 */
export const startTopics = createAsyncThunk<{ user: Partial<state.User> }>('topics/start', async () => {
  const firestore = getFirestore();
  const uid = getUid();
  const currentStage = 'topic';

  await updateDoc(doc(firestore, 'users', uid), { currentStage });
  return { user: { currentStage } };
});

/**
 * Create topic plan
 */
export const createTopicPlan = createAsyncThunk('topics/create-plan', async (plan: TopicPlanItem[]) => {
  const firestore = getFirestore();
  const batch = writeBatch(firestore);
  const uid = getUid();

  // create initial topic state
  const unlockIndex = plan.findIndex((userTopic) => userTopic.mandatory);
  const userTopics = plan.reduce<Record<string, state.Topic>>((userTopics, item, idx) => {
    const { topicId, mandatory, seq, tag = '' } = item;
    const userTopic: state.Topic = {
      mandatory,
      seq,
      tag,
      locked: idx !== unlockIndex,
      entries: {},
    };

    batch.set(doc(firestore, `users/${uid}/topics`, topicId), userTopic);
    return { ...userTopics, [item.topicId]: userTopic };
  }, {});

  // create followupUnlockAt
  const followupUnlockAt = Timestamp.fromMillis(
    Date.now() + Number(process.env.GATSBY_DAYS_UNLOCK_FOLLOWUP || 90) * 60 * 60 * 1000 * 24,
  );
  const userUpdate = { followupUnlockAt, currentStage: 'topic' } as const;
  batch.update(doc(firestore, `users/${uid}`), userUpdate);

  await batch.commit();
  return { topics: userTopics, user: userUpdate };
});

/**
 * Update topic data
 */
export const updateTopic = createAsyncThunk(
  'topics/update',
  async ({ topicKey, data }: { topicKey: string; data: Partial<state.Topic> }, thunkApi) => {
    const firestore = getFirestore();
    const uid = getUid();

    thunkApi.dispatch(actions.updateTopic({ topicKey, data }));
    await updateDoc(doc(firestore, `users/${uid}/topics`, topicKey), data);
  },
);

/**
 * Update topic entry content
 */
export const updateTopicEntry = createAsyncThunk(
  'topics/update-entry',
  async (
    {
      topicKey,
      entryKey,
      contentId,
      value,
    }: {
      topicKey: string;
      entryKey: string;
      contentId: string;
      value: any;
    },
    thunkApi,
  ) => {
    const firestore = getFirestore();
    const uid = getUid();

    thunkApi.dispatch(actions.updateTopicEntry({ topicKey, entryKey, data: { [contentId]: value } }));
    await updateDoc(doc(firestore, `users/${uid}/topics`, topicKey), { [`entries.${entryKey}.${contentId}`]: value });
  },
);

/**
 * Unlock topic
 */
export const unlockTopic = createAsyncThunk('topics/unlock', async ({ topicKey }: { topicKey: string }, thunkApi) => {
  const firestore = getFirestore();
  const uid = getUid();

  const state = thunkApi.getState() as RootState;
  const userTopics = state.profile.data?.topics;
  if (!userTopics) {
    return;
  }

  thunkApi.dispatch(actions.updateTopic({ topicKey, data: { locked: false } }));
  await updateDoc(doc(firestore, `users/${uid}/topics`, topicKey), {
    locked: false,
    unlockedAt: serverTimestamp(),
  });
});

/**
 * Set topic entry visited
 */
export const setEntryVisited = createAsyncThunk(
  'topics/set-entry-visited',
  async ({ topicKey, entryKey }: { topicKey: string; entryKey: string }, thunkApi) => {
    const firestore = getFirestore();
    const uid = getUid();

    const { seconds, nanoseconds } = Timestamp.now();
    thunkApi.dispatch(actions.updateTopicEntry({ topicKey, entryKey, data: { _visited: { seconds, nanoseconds } } }));
    await updateDoc(doc(firestore, `users/${uid}/topics`, topicKey), {
      [`entries.${entryKey}._visited`]: serverTimestamp(),
    });
  },
);
