import { getAuth } from 'firebase/auth';

export const getUid = () => {
  const auth = getAuth();
  const uid = auth.currentUser?.uid;
  if (!uid) {
    throw new Error('No current user');
  }
  return uid;
};
