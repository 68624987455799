import { createAction, createSlice } from '@reduxjs/toolkit';

import type { User } from 'firebase/auth';

export type AuthState = {
  loaded: boolean;
  userInfo: User | null;
};

export const authenticated = createAction<User | null>('authenticated');

export const initialState: AuthState = {
  loaded: false,
  userInfo: null,
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authenticated, (state, action) => {
      state.loaded = true;
      state.userInfo = action.payload;
    });
  },
});

export { actions, reducer };
